// import React, { useEffect } from 'react'
import React from 'react'
import { Local_Image } from '../../Image/Image'
import Footer from '../../component/Footer';
import Explore from '../../component/Explore';
import Billboards from '../../component/Billboards';
import SocialMedia from '../../component/Social Media';
import DirectMail from '../../component/Direct Mail';
import LandingPage from '../../component/Landing Page';
import ThePresent from '../../component/The Present';
import { Image } from 'react-bootstrap';

export default function Home() {
    const Track =
        [
            {
                src: "Image/35893_13_CSL_23_DTA_AUDIO_Present_30_Price_ENG.mp3",
                type: "audio/mpeg"
            }];
    const Video = {
        0: {
            src: "https://www.youtube.com/embed/wsn76x4Zqjw?si=l2LIxBTajSdTab4i"
        },
    };

    return (
        <div className='border-0'>
            <div className='Header w-100 top-0 position-fixed d-flex 
            align-items-center bg-white border-0'>
                <Image src={Local_Image.Logo} className='Image-box' alt='Logo' />
            </div>
            <div className='mt-5 position-relative'>
                <Image className='M-Image-Box Main-Image w-100 h-100' src={Local_Image.Main} style={{ objectFit: 'cover' }}></Image>
                <div className='Black-Screen-Container'>
                    <h1 className='text-white Text-Image M-Header' style={{ zIndex: "4" }}>
                        An <span className='amazing-text'>AMAZING<img className='amazing-icon' src={Local_Image.tickLine} alt='TickLine'/></span> evolution
                    </h1>
                </div>
            </div>
            <div>
                <Explore Video={Video} ></Explore>
            </div>
            <div>
                <Billboards Billboard={Local_Image.Billboard} BillboardPoster={Local_Image.BillboardPoster}></Billboards>
            </div>
            <section>
                <SocialMedia Image_Props={[Local_Image.SocialMedia_1,
                Local_Image.SocialMedia_2,
                Local_Image.SocialMedia_3]}></SocialMedia>
            </section>
            <section>
                <DirectMail Image_Direct={Local_Image.DirectMail} ></DirectMail>
            </section>
            <section>
                <ThePresent Track={Track}></ThePresent>
            </section>
            <section>
                <LandingPage Image_LandingPage={Local_Image.LandingPage}></LandingPage>
            </section>
            <div>
                <div className='d-flex align-items-center justify-content-center w-100 announce_btn'
                 style={{ backgroundColor: '#333333' }}>
                </div>
                <Footer />
            </div>
        </div>
    )
}
