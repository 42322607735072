import React from 'react'
import { Local_Image } from '../Image/Image'
import { Image } from 'react-bootstrap'
import './Footer.css'

export default function Footer() {

    const footerLink = [
        {
            "name": "Cookie Policy",
            "link": "https://www.cslplasma.com/cookie-policy"
        },
        {
            "name": "Privacy Policy",
            "link": "https://www.cslplasma.com/privacy-policy"
        },
        {
            "name": "Terms of Use",
            "link": "https://www.cslplasma.com/terms-of-use"
        },

        {
            "name": "Accessibility",
            "link": "https://www.cslplasma.com/accessibility"
        },
        {
            "name": "Sitemap",
            "link": "https://www.cslplasma.com/sitemap"
        },
    ]

    return (
        <div className='container csl-footer-simple-background'>
            <footer className='csl-confined-content csl-footer-simple'>
                <div className="d-flex flex-row footer-content">
                    <div className="col-12">
                        <div className="d-flex flex-row flex-wrap m-0">
                            <div className="col order-md-1 order-2">
                                <ul className="nav d-flex">
                                    {
                                        footerLink.map((item, index) => (
                                            <li className="nav-item d-flex mb-md-0 mb-2 align-items-center" key={index}>
                                                <a href={item.link} rel='noreferrer' target='_blank' className='nav-link p-0 fontsize16700'>{item.name}</a>
                                                {footerLink.length > index + 1 ? (
                                                    <span className="limiter">|</span>
                                                ) : ""}
                                            </li>
                                        ))
                                    }
                                </ul>
                            </div>
                            <div className="col-12 col-md-3 col-lg-3 col-xl-1 pb-4 order-md-2 order-1">
                                <Image className='footer-logo' src={Local_Image.Logo} />
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    )
}
