import React from 'react'
import { Image } from 'react-bootstrap'

export default function LandingPage({ Image_LandingPage }) {

    return (
        <div className='csl-confined-content csl-1200-content'>
            <div className='csl-full-section csl-gap-3 pt-0'>
                <h2 className='text-center w-100 All-Header mb-0'>Landing Page</h2>
                <div className='Shadow-Box'>
                    <Image className='w-100' src={Image_LandingPage} />
                </div>
                <a className='rounded-pill Shadow-Box Background-Red csl-button'
                    target="_blank" href='https://www.cslplasma.com/new-donor-offer' rel='noreferrer'>
                    <strong className='Button-Text '>Visit the Landing Page</strong>
                </a>
            </div>
        </div>
    )
}
