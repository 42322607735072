import React, { useEffect, useState } from 'react'
import { Image } from 'react-bootstrap'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Autoplay } from 'swiper/modules';

export default function SocialMedia(props) {
    const { Image_Props } = props;
    const [Array_Swiper, setArray_Swiper] = useState([]);
    useEffect(() => {
        const TempArray = [...Image_Props.map((items, index) => {
            return (
                <SwiperSlide className='Shadow-Box' key={index} style={{ height: "fit-content", zIndex: 10 }}>
                    <Image className='w-100 SwiperSlide-Image' src={items} alt='Social Media 3' />
                </SwiperSlide>
            )
        }), ...Image_Props.map((items, index) => {
            return (
                <SwiperSlide className='Shadow-Box' key={index + 3} style={{ height: "fit-content", zIndex: 10 }}>
                    <Image className='w-100 SwiperSlide-Image' src={items} alt='Social Media 3' />
                </SwiperSlide>
            )
        })
        ];
        setArray_Swiper(TempArray);
    }, [Image_Props])
    return (
        <div className='csl-confined-content csl-1200-content'>
            <div className='csl-full-section csl-gap-3'>
                <h2 className='text-center w-100 All-Header mb-0'>
                    Social Media
                </h2>
                <div className='d-flex justify-content-center flex-column align-items-center w-100'>
                    <Image className='Phone-Social-Media-Image' src="Image/Social-Media-Phone-1.png" />
                    <div className='w-100 position-relative'>
                        <div className=' d-flex justify-content-center'>
                            <Image className='position-absolute h-100 Phone-Social-Media-Image' src="Image/Social-Media-Phone-2.png" />
                        </div>
                        <Swiper className='All-Margin w-100 mySwiper mySwiper-Container'
                            loop={true}
                            spaceBetween={80}
                            slidesPerView={3}
                            slidesPerGroup={1}
                            autoplay={{
                                delay: 2500,
                                disableOnInteraction: false,
                            }}
                            breakpoints={{
                                0: {
                                    slidesPerView: 3,
                                    spaceBetween: 20
                                },
                                230: {
                                    slidesPerView: 3,
                                    spaceBetween: 20
                                },
                                480: {
                                    slidesPerView: 3,
                                    spaceBetween: 30
                                },
                                640: {
                                    slidesPerView: 3,
                                    spaceBetween: 50
                                },
                                1000: {
                                    slidesPerView: 3,
                                    spaceBetween: 80
                                }
                            }}
                            modules={[Autoplay]}
                        >
                            {Array_Swiper}
                        </Swiper>
                    </div>
                    <Image className='Phone-Social-Media-Image' src="Image/Social-Media-Phone-3.png" />
                </div>
            </div>
        </div>
    )
}
