import React from 'react'
import { Local_Image } from '../Image/Image'

export default function Explore({ Video }) {

    return (
        <div>
            <div className='csl-confined-content csl-1200-content'>
                <div className='csl-full-section csl-gap-3 pb-0'>
                    <h2 className='Explore-Header csl-color text-center mb-0'>
                        Join us as we explore a world full of amazing potential.
                    </h2>
                    <div className='row'>
                        <div className='col-12 col-lg-7'>
                            <img src={Local_Image.Explore} alt='' className='Explore-Image' />
                        </div>
                        <div className='col-12 col-lg-5 mt-5'>
                            <p className='text-center Explore-Content text-dark mb-0'>
                                More than two years ago, CSL Plasma launched its ‘Do the Amazing’ campaign – a powerful marketing initiative that focused on the life-saving contribution that plasma provides to those suffering from rare diseases and medical conditions.
                            </p><br />
                            <p className='text-center Explore-Content text-dark mb-0'>
                                We are excited to announce the next phase of ‘Do the Amazing’ with a new campaign that shifts the focus from donors as heroes to having donors in more relatable instances combined with impactful imagery to convey how patients experience everyday moments and thrive in their lives.
                            </p><br />
                            <p className='text-center Explore-Content text-dark mb-0'>
                                Now launching, our new “Do the AMAZING” campaign celebrates all the good plasma brings to people’s lives. Moments made possible by you—the CSL employees that do the amazing every single day. Let’s take a look at the many ways it comes to life.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className='csl-confined-content csl-1000-content'>
                <div className='csl-full-section csl-gap-3'>
                    <div className='w-100 iframe-section'>
                        <iframe className='Explore-Video w-100'
                            src={Video[0].src} title="YouTube video player"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            allowFullScreen></iframe>
                    </div>
                </div>
            </div>
        </div>
    )
}
