export const Local_Image = {
    Logo: "Image/logo.png",
    MainMobile:"Image/Main-Mobile.png",
    Billboard: "Image/BilldboardImage.png",
    BillboardPoster: "Image/Poster.png",
    DirectMail: "Image/Diret-mail.png",
    Main:"Image/Main-Image.png",
    SocialMedia_1:"Image/social-Image-Donor.jpg",
    SocialMedia_Phone:"Image/Social-Media-Phone.png",
    SocialMedia_2:"Image/Social-Media-Graduation.png",
    SocialMedia_3:"Image/Social-Media-Dance.png",
    LandingPage:"Image/LandingPage.png",
    tickLine: "Image/Tick.png",
    Explore:"Image/Explore-Images.jpg"
}