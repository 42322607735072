import React from 'react'
import { Image } from 'react-bootstrap'

export default function Billboards({ Billboard, BillboardPoster }) {
    return (
        <div className='csl-full-section csl-gap-3 p-0'>
            <h2 className='text-center w-100 All-Header mb-0'>
                Billboards
            </h2>
            <Image className='billdboard-img' src={Billboard} alt='Billboard'></Image>
            <div className='w-100 d-flex flex-column align-items-center justify-content-center'>
                <div className='Billdboards-Poster-Box bg-white'>
                    <Image className='w-100 object-fit-cover Shadow-Box' src={BillboardPoster}></Image>
                </div>
            </div>
        </div>
    )
}
