import React, { useEffect, useState } from 'react'

export default function ThePresent({ Track }) {
    const [RangeChange, setRangeChange] = useState({
        Timer: 0,
        Sound: 100,
    });

    useEffect(() => {
        var audio = document.getElementById('audio'),
            progressbar = document.getElementById('AudioProgressBar');
        audio.addEventListener('timeupdate', function () {
            var currentTime = parseInt(audio.currentTime / 60);
            var duration = parseInt(audio.duration / 60);
            var secCurrentTime = parseInt(audio.currentTime % 60);
            var secduration = parseInt(audio.duration % 60);
            document.getElementById("time").innerHTML = currentTime + ":" + (secCurrentTime < 10 ? '0' + secCurrentTime : secCurrentTime) + " / <span class='Total'>" + duration + ":" + secduration + "</span>";
            const percentage = audio.currentTime * 100 / audio.duration;
            if (percentage === 100) {
                progressbar.style.width = percentage + "%";
            } else {
                progressbar.style.width = percentage + (percentage > 40 ? 0 : 1.2) + "%";
            }
            setRangeChange({...RangeChange,  Timer: audio.currentTime * 100 / audio.duration })
            if (currentTime === duration && secCurrentTime === secduration) {
                play.style.display = "block";
                pause.style.display = "none";
            }
        });

        var yourAudio = document.getElementById('audio'),
            play = document.getElementById('audioControlPlay'),
            pause = document.getElementById('audioControlPause');

        pause.onclick = function () {
            play.style.display = "block";
            pause.style.display = "none";
            yourAudio["pause"]();
        }

        play.onclick = function () {
            play.style.display = "none";
            pause.style.display = "block";
            yourAudio["play"]();
        };

    }, [RangeChange])

    useEffect(() => {
        const mute = document.getElementById('audioSoundMute'),
            muted = document.getElementById('audioSoundMuted');
        var yourAudio = document.getElementById('audio');

        const SoundProgressbar = document.getElementById("AudioProgressBarSound");
        muted.onclick = () => {
            if (yourAudio.volume * 100 !== 0) {
                mute.style.display = "block";
                muted.style.display = "none";
            }
            yourAudio["muted"] = false;
            SoundProgressbar.style.width = (yourAudio.volume * 100) + "%";
            // audio.volume = e.target.value / 100;
            setRangeChange({...RangeChange, Sound: (yourAudio.volume * 100) });
        }

        mute.onclick = () => {
            mute.style.display = "none";
            muted.style.display = "block";
            yourAudio["muted"] = true;
            SoundProgressbar.style.width = 0 + "%";
            setRangeChange({...RangeChange,  Sound: 0 });
        }
    }, [RangeChange])

    const ChangeValue = (e) => {
        e.preventDefault();
        const mute = document.getElementById('audioSoundMute'),
            muted = document.getElementById('audioSoundMuted');
        var audio = document.getElementById('audio');
        if (e.target.name === "Timer") {
            const progressbar = document.getElementById('AudioProgressBar');
            progressbar.style.width = e.target.value + "%";
            audio.currentTime = e.target.value * audio.duration / 100;
        } else {
            const SoundProgressbar = document.getElementById("AudioProgressBarSound");
            SoundProgressbar.style.width = e.target.value + "%";
            if (e.target.value < 1) {
                mute.style.display = "none";
                muted.style.display = "block";
            } else {
                mute.style.display = "block";
                muted.style.display = "none";
            }
            audio.volume = e.target.value / 100;
        }
        setRangeChange({ ...RangeChange,[e.target.name]: e.target.value });
    }
    return (
        <div className='csl-confined-content csl-1200-content'>
            <div className='csl-full-section csl-gap-3 pt-0'>
                <h2 className='text-center All-Header' style={{ fontWeight: "400" }}>
                    :30 Radio:&nbsp;
                    <span className='fw-bolder'>
                        The Present
                    </span>
                </h2>

                <div id="audioPlayer" className='w-100'>
                    <audio id="audio" controls className='d-none'>
                        <source src={Track[0].src} type={Track[0].type} />
                        Your browser does not support the audio tag.
                    </audio>
                    <div id="AudioContainer" className="d-flex justify-content-center align-items-center p-2 ps-3 pe-3">
                        <div className='row w-100'>
                            <div className='col-md-10 col-8'>
                                <div className='Row-No-Gap row row-cols-lg-2 row-cols-1 w-100 d-flex align-items-center'>
                                    <div className='col-lg-10 col'>
                                        <div className='d-flex align-items-center'>
                                            <div role="button">
                                                <span id="audioControlPlay">
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="M-Icon bi bi-play-fill" viewBox="0 0 16 16">
                                                        <path d="m11.596 8.697-6.363 3.692c-.54.313-1.233-.066-1.233-.697V4.308c0-.63.692-1.01 1.233-.696l6.363 3.692a.802.802 0 0 1 0 1.393z" />
                                                    </svg>
                                                </span>
                                                <span id="audioControlPause">
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="M-Icon bi bi-pause-fill" viewBox="0 0 16 16">
                                                        <path d="M5.5 3.5A1.5 1.5 0 0 1 7 5v6a1.5 1.5 0 0 1-3 0V5a1.5 1.5 0 0 1 1.5-1.5m5 0A1.5 1.5 0 0 1 12 5v6a1.5 1.5 0 0 1-3 0V5a1.5 1.5 0 0 1 1.5-1.5" />
                                                    </svg>
                                                </span>
                                            </div>
                                            <div role="button" className='w-100'>
                                                <div className="progress bg-dark ms-3 me-3 d-flex position-relative custom-progress" id='AudioProgressBarContainer' role="progressbar" aria-label="Basic example" aria-valuemin={0} aria-valuemax={100}>
                                                    <div id="AudioProgressBar" className="progress-bar" />
                                                    <div className='position-absolute w-100' style={{ top: "-7px" }}>
                                                        <input type="range" name='Timer' className="form-range" id="Audio-Range-Timer" value={RangeChange.Timer} onChange={ChangeValue} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <p id="time" className='col-lg-2 col mb-0 mt-lg-0 mt-3 content-center text-lg-center'>
                                        0:00 / <span className="Total">0:00</span>
                                    </p>
                                </div>
                            </div>
                            <div className="Audio_Tool col-md-2 col-4">
                                <div className='d-flex justify-content-center align-items-center'>
                                    <div role="button">
                                        <span id="audioSoundMute">
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="M-Icon bi bi-volume-up-fill" viewBox="0 0 16 16">
                                                <path d="M11.536 14.01A8.473 8.473 0 0 0 14.026 8a8.473 8.473 0 0 0-2.49-6.01l-.708.707A7.476 7.476 0 0 1 13.025 8c0 2.071-.84 3.946-2.197 5.303l.708.707z" />
                                                <path d="M10.121 12.596A6.48 6.48 0 0 0 12.025 8a6.48 6.48 0 0 0-1.904-4.596l-.707.707A5.483 5.483 0 0 1 11.025 8a5.483 5.483 0 0 1-1.61 3.89z" />
                                                <path d="M8.707 11.182A4.486 4.486 0 0 0 10.025 8a4.486 4.486 0 0 0-1.318-3.182L8 5.525A3.489 3.489 0 0 1 9.025 8 3.49 3.49 0 0 1 8 10.475zM6.717 3.55A.5.5 0 0 1 7 4v8a.5.5 0 0 1-.812.39L3.825 10.5H1.5A.5.5 0 0 1 1 10V6a.5.5 0 0 1 .5-.5h2.325l2.363-1.89a.5.5 0 0 1 .529-.06z" />
                                            </svg>
                                        </span>
                                        <span id="audioSoundMuted">
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="M-Icon bi bi-volume-mute-fill" viewBox="0 0 16 16">
                                                <path d="M6.717 3.55A.5.5 0 0 1 7 4v8a.5.5 0 0 1-.812.39L3.825 10.5H1.5A.5.5 0 0 1 1 10V6a.5.5 0 0 1 .5-.5h2.325l2.363-1.89a.5.5 0 0 1 .529-.06zm7.137 2.096a.5.5 0 0 1 0 .708L12.207 8l1.647 1.646a.5.5 0 0 1-.708.708L11.5 8.707l-1.646 1.647a.5.5 0 0 1-.708-.708L10.793 8 9.146 6.354a.5.5 0 1 1 .708-.708L11.5 7.293l1.646-1.647a.5.5 0 0 1 .708 0z" />
                                            </svg>
                                        </span>
                                    </div>
                                    <div id='AudioProgressBarSoundContainer' className="ms-2 w-100 position-relative progress bg-dark custom-progress" role="progressbar" aria-label="Basic example" aria-valuemin={0} aria-valuemax={100}>
                                        <div id="AudioProgressBarSound" className="progress-bar" />
                                        <div className='position-absolute w-100' style={{ top: "-7px" }}>
                                            <input type="range" className="form-range" name='Sound' id="Audio-Range-Sound" value={RangeChange.Sound} onChange={ChangeValue} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
