import './App.css';
import Home from './page/Home/Home';
import 'bootstrap/dist/css/bootstrap.css'

function App() {
  return (
   <Home/>
  );
}

export default App;