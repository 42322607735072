import React from 'react'
import { Image } from 'react-bootstrap'

export default function DirectMail({ Image_Direct }) {
    return (
        <div className='csl-confined-content csl-1200-content'>
            <div className='csl-full-section csl-gap-3 pt-0'>
                <h2 className='text-center w-100 All-Header mb-0'>Direct Mail</h2>
                <Image className='w-100 Shadow-Box' src={Image_Direct} />
            </div>
        </div>
    )
}
